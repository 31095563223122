<template>
  <!-- 小组里的成员列表 -->
  <div class="group__members-list-container">
    <div class="part__search-container">
      <van-search v-model="keyword" placeholder="搜索"></van-search>
    </div>
    <div class="members-lsit__header">
      <div class="header__item">成员</div>
      <div class="header__item">最近访问</div>
    </div>
    <div class="members-list__main-body">
      <router-link
        class="member-invite-row"
        v-if="group"
        :to="{
          path: `/users/search?groupid=${groupID}&leaderuid=${group.info.author.uid}&type=invite`,
        }"
        tag="div"
      >
        <div class="icon">
          <svg-icon icon-class="icon-upload-plus" class="icon-container"></svg-icon>
        </div>
        <p>邀请</p>
      </router-link>
      <template v-if="resultList.length > 0">
        <div class="member__item-wrapper">
          <div
            class="member__item"
            :class="{
              '--operation': operationPower,
            }"
            v-for="(item, index) in resultList"
            :key="item.uid"
            :before-close="beforeClose"
            :data-uid="item.uid"
            @close="onSwipeCellClose"
            @click="
              onShowMangeList({
                uid: item.uid,
                role: item.is_author ? 1 : item.isGroupAdmin ? 2 : 3,
              })
            "
          >
            <div class="item__options">
              <strong>{{ index + 1 }}</strong>
            </div>
            <div class="item__options">
              <div class="item__headpic" @click.stop="userJump(item.uid)">
                <img :src="item.headimgurl" alt="" />
              </div>
              <div @click.stop="userJump(item.uid)" class="item__name">{{ item.nickname }}</div>
              <div class="item__comment __role" v-if="item.is_author === true || item.isGroupAdmin">
                <template v-if="item.is_author === true">创建人</template>
                <template v-else-if="item.isGroupAdmin">管理员</template>
              </div>
            </div>
            <div class="item__options">
              <strong v-if="item.lastVisitat > 0">{{
                item.lastVisitat | parseTime("{y}.{m}.{d} {h}:{i}")
              }}</strong>
              <strong v-else></strong>
            </div>
          </div>
        </div>
      </template>
      <van-loading style="text-align: center" v-else>加载中...</van-loading>
    </div>
    <slogan text="✖ 同伴的标志 ✖"></slogan>
    <van-button class="bt-back" @click="goback">返回</van-button>
    <van-popup
      v-if="operationPower"
      v-model="popUpShow"
      position="bottom"
      :close-on-click-overlay="false"
      class="member-operation-container"
    >
      <p class="admin-hint">管理员权限：编辑小组介绍，成员审核、移出，置顶话题</p>
      <div class="apply__bts">
        <van-button
          class="bt__item"
          :loading="doActioning === 'manage'"
          v-if="isAuthor"
          @click="onAdminManage"
          >{{ showAdminManagerText() }}</van-button
        >
        <van-button class="bt__item red" :loading="doActioning === 'remove'" @click="onRemoveMember"
          >移出小组</van-button
        >
        <van-button class="bt__item" @click="onCancel">取消</van-button>
      </div>
    </van-popup>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import forbiddenMixin from '@/mixins/forbidden';
import slogan from '../common/_slogan.vue';

const groupRoleMap = new Map();
groupRoleMap.set('author', 1);
groupRoleMap.set('admin', 2);
groupRoleMap.set('member', 3);

export default {
  name: 'group-detail-members',
  props: ['id'],
  mixins: [forbiddenMixin],
  components: {
    slogan,
  },
  data() {
    return {
      requesting: false,
      lists: [],
      currenConfirm: 0,
      isAuthor: false,
      isAdmin: false,
      popUpShow: false,
      pendingUserinfo: null,
      doActioning: '',
      group: null,
      keyword: '',
      resultList: [],
    };
  },
  watch: {
    keyword() {
      this.onSearch();
    },
    lists(val) {
      this.resultList = Array.from(val);
    },
  },
  computed: {
    ...mapState(['user']),
    groupInfo() {
      return this.$parent.info;
    },
    managerUrl() {
      return `/group/${this.groupID}/manager?author=${this.groupInfo.author.uid}`;
    },
    groupID() {
      return this.$route.params.groupID;
    },
    operationPower() {
      return this.isAdmin || this.isAuthor;
    },
  },
  mounted() {
    this.getList();
    this.getGroup();
  },
  methods: {
    getList() {
      const that = this;
      if (!that.requesting) {
        that.requesting = true;
        that.$request
          .get(`api/group/${that.groupID}/member/list`)
          .then((res) => {
            const lists = res.data;
            that.lists.push(...lists);
          })
          .catch((err) => {
            if (err.status === 403 || err.status === 404 || err.status === 500) {
              const auid = err.data ? err.data.author_uid : 0;
              const url = auid > 0 ? `/group/${that.groupID}` : '/';
              const btMsg = auid > 0 ? '查看小组' : '返回首页';
              this.notifyForbidden('group', err.message, btMsg, url);
            }
          })
          .finally(() => {
            that.requesting = false;
          });
      }
    },
    beforeClose({ position, instance }) {
      const that = this;
      switch (position) {
        case 'right':
          that.currenConfirm = instance.$el.dataset.uid;
          that.onConfirmDel(instance.$el.querySelector('.confirm')).then(() => {
            if (!that.requesting) {
              that.requesting = true;
              that.$request
                .get(`api/group/${that.groupID}/member/delete/${that.currenConfirm}`)
                .then((res) => {
                  const tempList = that.lists.filter(
                    (item) => Number(item.uid) !== Number(res.data),
                  );
                  that.lists = tempList;
                })
                .catch((e) => {
                  that.$dialog.alert({
                    title: '操作提示',
                    message: e.message,
                  });
                })
                .finally(() => {
                  that.requesting = false;
                  that.currenConfirm = 0;
                  instance.close();
                });
            }
          });
          break;
        default:
          that.currenConfirm = 0;
          instance.close();
          break;
      }
    },
    onSwipeCellClose() {
      this.currenConfirm = 0;
    },
    onConfirmDel(obj) {
      return new Promise((resolve) => {
        obj.addEventListener('click', () => resolve('submit'));
      });
    },
    onInvite() {},
    getGroup() {
      const that = this;
      that.$request.get(`api/group/${that.groupID}`).then((res) => {
        const { data } = res;
        that.group = data;
        that.isAdmin = that.group.isAdmin;
        that.isAuthor = Number(that.group.info.author.uid) === Number(that.user.uid);
      });
    },
    onShowMangeList(info) {
      const that = this;
      if (that.operationPower) {
        // 弹窗
        that.pendingUserinfo = info;
        that.popUpShow = true;
      }
    },
    onCancel() {
      this.doActioning = '';
      this.pendingUserinfo = null;
      this.popUpShow = false;
    },
    onAdminManage() {
      const that = this;
      if (that.doActioning === '') {
        const temp = Array.from(that.lists);
        that.doActioning = 'manage';
        const p = new Promise((resolve, reject) => {
          if (that.pendingUserinfo.role === groupRoleMap.get('admin')) {
            that
              .beforeSumbit('确定撤销其管理员？')
              .then(() => {
                that.removeAdmin(that.pendingUserinfo.uid).then(() => {
                  temp.map((item) => {
                    const tempItem = item;
                    if (Number(tempItem.uid) === Number(that.pendingUserinfo.uid)) {
                      tempItem.isGroupAdmin = false;
                    }
                    return tempItem;
                  });
                  resolve(temp);
                });
              })
              .catch((err) => {
                console.log(err);
                // reject(err.message);
                that.doActioning = '';
              });
          } else {
            that
              .setAdmin(that.pendingUserinfo.uid)
              .then(() => {
                temp.map((item) => {
                  const tempItem = item;
                  if (Number(tempItem.uid) === Number(that.pendingUserinfo.uid)) {
                    tempItem.isGroupAdmin = true;
                  }
                  return tempItem;
                });
                resolve(temp);
              })
              .catch((err) => {
                reject(err.message);
              });
          }
        });
        p.then((data) => {
          that.lists = data;
        })
          .catch((err) => {
            that.$notify({
              message: err,
            });
          })
          .finally(() => {
            that.onCancel();
          });
      }
    },
    onRemoveMember() {
      const that = this;
      const link = `api/group/${this.groupID}/member/delete/${that.pendingUserinfo.uid}`;
      if (that.doActioning === '') {
        that.doActioning = 'remove';
        that
          .beforeSumbit('确定移出小组？')
          .then(() => {
            that.$request
              .get(link)
              .then((res) => {
                that.lists = that.lists.filter((item) => Number(item.uid) !== Number(res.data));
                that.$notify({
                  type: 'success',
                  message: '操作成功',
                });
              })
              .catch((err) => {
                that.$notify({
                  message: err.message,
                });
              })
              .finally(() => {
                that.onCancel();
              });
          })
          .catch(() => {
            that.doActioning = '';
          });
      }
    },
    beforeSumbit(msg) {
      const that = this;
      return new Promise((resolve, reject) => {
        that.$dialog
          .confirm({
            message: msg,
            beforeClose: (action, done) => {
              done();
              if (action === 'confirm') {
                resolve();
              } else {
                reject();
              }
            },
          })
          .catch(() => {
            reject();
          });
      });
    },
    showAdminManagerText() {
      return this.pendingUserinfo && this.pendingUserinfo.role === groupRoleMap.get('admin')
        ? '撤销管理员'
        : '设为管理员';
    },
    removeAdmin(uid) {
      const link = `api/group/${this.groupID}/member-role/remove-admin/${uid}`;
      return this.$request.get(link);
    },
    setAdmin(uid) {
      const link = `api/group/${this.groupID}/member-role/set-admin/${uid}`;
      return this.$request.get(link);
    },
    goback() {
      this.$router.push({
        path: `/group/${this.groupID}/topics`,
      });
    },
    onSearch() {
      const that = this;
      if (that.keyword) {
        const temp = that.resultList.filter((item) => {
          const nickname = item.nickname.match(that.keyword);
          return !(nickname === null);
        });
        that.resultList = temp;
      } else {
        that.resultList = that.lists;
      }
    },
  },
};
</script>

<style></style>

var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"group__members-list-container"},[_c('div',{staticClass:"part__search-container"},[_c('van-search',{attrs:{"placeholder":"搜索"},model:{value:(_vm.keyword),callback:function ($$v) {_vm.keyword=$$v},expression:"keyword"}})],1),_vm._m(0),_c('div',{staticClass:"members-list__main-body"},[(_vm.group)?_c('router-link',{staticClass:"member-invite-row",attrs:{"to":{
        path: `/users/search?groupid=${_vm.groupID}&leaderuid=${_vm.group.info.author.uid}&type=invite`,
      },"tag":"div"}},[_c('div',{staticClass:"icon"},[_c('svg-icon',{staticClass:"icon-container",attrs:{"icon-class":"icon-upload-plus"}})],1),_c('p',[_vm._v("邀请")])]):_vm._e(),(_vm.resultList.length > 0)?[_c('div',{staticClass:"member__item-wrapper"},_vm._l((_vm.resultList),function(item,index){return _c('div',{key:item.uid,staticClass:"member__item",class:{
            '--operation': _vm.operationPower,
          },attrs:{"before-close":_vm.beforeClose,"data-uid":item.uid},on:{"close":_vm.onSwipeCellClose,"click":function($event){return _vm.onShowMangeList({
              uid: item.uid,
              role: item.is_author ? 1 : item.isGroupAdmin ? 2 : 3,
            })}}},[_c('div',{staticClass:"item__options"},[_c('strong',[_vm._v(_vm._s(index + 1))])]),_c('div',{staticClass:"item__options"},[_c('div',{staticClass:"item__headpic",on:{"click":function($event){$event.stopPropagation();return _vm.userJump(item.uid)}}},[_c('img',{attrs:{"src":item.headimgurl,"alt":""}})]),_c('div',{staticClass:"item__name",on:{"click":function($event){$event.stopPropagation();return _vm.userJump(item.uid)}}},[_vm._v(_vm._s(item.nickname))]),(item.is_author === true || item.isGroupAdmin)?_c('div',{staticClass:"item__comment __role"},[(item.is_author === true)?[_vm._v("创建人")]:(item.isGroupAdmin)?[_vm._v("管理员")]:_vm._e()],2):_vm._e()]),_c('div',{staticClass:"item__options"},[(item.lastVisitat > 0)?_c('strong',[_vm._v(_vm._s(_vm._f("parseTime")(item.lastVisitat,"{y}.{m}.{d} {h}:{i}")))]):_c('strong')])])}),0)]:_c('van-loading',{staticStyle:{"text-align":"center"}},[_vm._v("加载中...")])],2),_c('slogan',{attrs:{"text":"✖ 同伴的标志 ✖"}}),_c('van-button',{staticClass:"bt-back",on:{"click":_vm.goback}},[_vm._v("返回")]),(_vm.operationPower)?_c('van-popup',{staticClass:"member-operation-container",attrs:{"position":"bottom","close-on-click-overlay":false},model:{value:(_vm.popUpShow),callback:function ($$v) {_vm.popUpShow=$$v},expression:"popUpShow"}},[_c('p',{staticClass:"admin-hint"},[_vm._v("管理员权限：编辑小组介绍，成员审核、移出，置顶话题")]),_c('div',{staticClass:"apply__bts"},[(_vm.isAuthor)?_c('van-button',{staticClass:"bt__item",attrs:{"loading":_vm.doActioning === 'manage'},on:{"click":_vm.onAdminManage}},[_vm._v(_vm._s(_vm.showAdminManagerText()))]):_vm._e(),_c('van-button',{staticClass:"bt__item red",attrs:{"loading":_vm.doActioning === 'remove'},on:{"click":_vm.onRemoveMember}},[_vm._v("移出小组")]),_c('van-button',{staticClass:"bt__item",on:{"click":_vm.onCancel}},[_vm._v("取消")])],1)]):_vm._e()],1)
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"members-lsit__header"},[_c('div',{staticClass:"header__item"},[_vm._v("成员")]),_c('div',{staticClass:"header__item"},[_vm._v("最近访问")])])
}]

export { render, staticRenderFns }